import { Components, Theme } from '@mui/material/styles';

const MuiTooltip: Components<Theme>['MuiTooltip'] = {
  styleOverrides: {
    popper: {
      zIndex: 2000,
    },
    tooltip: ({ theme }) => ({
      backgroundColor: theme.palette.darkPurple.main,
      color: theme.palette.darkerPurple.dark,
      fontSize: theme.typography.body2.fontSize,
      padding: theme.spacing(1),
      lineHeight: 1,
    }),
    arrow: ({ theme }) => ({
      color: theme.palette.darkPurple.main,
    }),
  },
};
export default MuiTooltip;
