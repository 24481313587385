import { MentionsInput, Mention, SuggestionDataItem, MentionsInputProps, SuggestionFunc } from 'react-mentions';
import { forwardRef, ReactElement, useMemo, useRef } from 'react';
import { Box, Stack, Typography, useTheme } from '@mui/material';
import Icon from '@/components/Icon/Icon';

interface RoundedMentionInput extends Omit<MentionsInputProps, 'children' | 'onSubmit'> {
  mentions?: SuggestionDataItem[];
  startIcon?: ReactElement;
  endIcon?: ReactElement;
}

const MAX_HEIGHT = 300;
const MIN_MAX_HEIGHT = 220;

const RoundedMentionInput = forwardRef<HTMLTextAreaElement | null, RoundedMentionInput>(
  ({ value, mentions = [], startIcon, endIcon, ...props }, ref) => {
    const { palette } = useTheme();
    const refContainer = useRef<HTMLDivElement | null>(null);
    const inputRect = refContainer?.current && refContainer?.current.getBoundingClientRect();

    const suggestionHeight = useMemo(
      () => ((inputRect?.top || 0) >= window.outerHeight - MAX_HEIGHT ? MAX_HEIGHT : (inputRect?.top || 0) - MIN_MAX_HEIGHT),
      [inputRect?.top],
    );

    const renderSuggestions: SuggestionFunc = (_suggestion, _search, highlightedDisplay, index) => (
      <Stack key={index} flexDirection="row" alignItems="center" gap={2}>
        <Box>
          <Icon name="pdf" fontSize="small" />
        </Box>
        <Typography variant="body2">{highlightedDisplay}</Typography>
      </Stack>
    );

    return (
      <Stack
        sx={{
          flexDirection: 'row',
          alignItems: 'center',
          borderRadius: 5,
          boxShadow: 1,
          border: `1px solid ${palette.darkPurple.main}`,
          backgroundColor: palette.background.default,
          position: 'relative',
          lineHeight: 'body2.fontSize',
        }}
        ref={refContainer}
      >
        {startIcon && startIcon}
        <MentionsInput
          {...props}
          value={value}
          forceSuggestionsAboveCursor
          inputRef={ref}
          className="mentions"
          style={{
            suggestions: {
              list: {
                maxHeight: suggestionHeight,
              },
            },
          }}
        >
          <Mention
            renderSuggestion={renderSuggestions}
            data={mentions}
            trigger="@"
            displayTransform={(id, display) => `@${display}`}
          />
        </MentionsInput>
        {endIcon && endIcon}
      </Stack>
    );
  },
);

export default RoundedMentionInput;
