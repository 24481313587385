import { FC, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from '@tanstack/react-query';
import { ROUTER_IDS, toHome, toProjectHomepage, toProjectPages, toProjectQueries, toProjectTakeoff } from '@/services/linker';
import { useProject } from '@/views/Project/hooks/useProject';
import ViewHeader, { ViewHeaderTab } from '@/components/ViewHeader/ViewHeader';
import UserPlus from '@/assets/user-plus.svg?react';
import { useDocuments } from '@/views/Project/hooks/useDocuments';
import { AvatarGroup, Box, IconButton, Stack, Tooltip, Typography, useTheme } from '@mui/material';
import UserAvatar from '@/components/Avatar/UserAvatar';
import { useAuth } from '@/hooks/useAuth';
import { User } from '@/api/generated';
import ShareProjectDialog from '@/views/ShareProjectDialog/ShareProjectDialog';
import { addUserToProjectCollaborationList, removeUserFromProjectCollaborationList } from '@/utils/projectCollaborationList';
import useRouteId from '@/hooks/useRouteId';
import { updateProjectCache } from '@/utils/updateProjectCache';
import { HomepageActions } from '@/views/Project/views/Header/types';
import { useUrlAction } from '@/views/Project/views/Homepage/utils/useUrlAction';

const MAX_LENGTH = 3;

const Header: FC = () => {
  const [isOpenShareProjectModal, setIsOpenShareProjectModal] = useState<boolean>(false);
  const { t } = useTranslation('project');
  const queryClient = useQueryClient();
  const { projectSlug, project } = useProject();
  const { documentId } = useDocuments();
  const routeId = useRouteId();
  const { shadows } = useTheme();
  const { currentUser } = useAuth();

  const tabs: ViewHeaderTab[] = useMemo(
    () =>
      [
        {
          label: t('tabs.homepage'),
          value: ROUTER_IDS.PROJECT_HOME_PAGE,
          to: toProjectHomepage({ projectSlug, documentId: documentId! }),
        },
        !!documentId && {
          label: t('tabs.queries'),
          value: ROUTER_IDS.PROJECT_QUERIES,
          to: toProjectQueries({ projectSlug, documentId: documentId! }),
        },
        !!documentId && {
          label: t('tabs.takeoff'),
          value: ROUTER_IDS.PROJECT_TAKEOFF,
          to: toProjectTakeoff({ projectSlug, documentId: documentId! }),
        },
        {
          label: t('tabs.pages'),
          value: ROUTER_IDS.PROJECT_PAGES,
          to: toProjectPages({ projectSlug, documentId: documentId! }),
        },
      ].filter(tab => !!tab),
    [projectSlug, documentId, t],
  );

  const collaborationUsers = useMemo(() => {
    if (!project?.collaborate_with) return [];

    return project.collaborate_with
      .filter(user => user._id !== currentUser?._id)
      .map(user => ({
        title: t('header.sharedWith.fullName', { firstName: user.firstName, lastName: user.lastName }),
        userIcon: <UserAvatar size={32} user={user} sx={{ '&.MuiAvatar-root': { '&:last-child': { marginLeft: -2 } } }} />,
      }));
  }, [project.collaborate_with, t, currentUser]);

  const toggleModal = () => setIsOpenShareProjectModal(prev => !prev);

  const onShare = (sharedUser: User) => {
    updateProjectCache({ projectSlug, queryClient }, prevProject =>
      addUserToProjectCollaborationList({ user: sharedUser, project: prevProject! }),
    );
  };

  const onUnshare = (unsharedUser: User) => {
    updateProjectCache({ projectSlug, queryClient }, prevProject =>
      removeUserFromProjectCollaborationList({ user: unsharedUser, project: prevProject! }),
    );
  };

  useUrlAction(HomepageActions.OPEN_SHARE_DIALOG, () => toggleModal());

  return (
    <ViewHeader
      sx={{
        backgroundColor: 'common.white',
        mb: 0,
        maxHeight: '100vh',
        overflow: 'hidden',
        transition: 'max-height 0.2s, margin 0.2s',
        flex: '0 0 auto',
        borderBottom: 'none',
        boxShadow: 2,
        zIndex: 2,
        display: 'flex',
        flexDirection: 'row-reverse',
        alignItems: 'center',
        justifyContent: 'space-between',
      }}
      backTo={toHome()}
      tabs={tabs}
      currentTab={routeId}
      tabSx={{ px: 5, '& .MuiTab-root': { pt: 2.5, fontSize: 'body1.fontSize' } }}
    >
      <Stack direction="row" alignItems="center" sx={{ mr: 2.5 }}>
        <AvatarGroup
          max={MAX_LENGTH}
          renderSurplus={surplus => (
            <IconButton color="primary" onClick={toggleModal} title={t('header.sharedWith.title')}>
              <Typography variant="body2">+{surplus.toString()[0]}</Typography>
            </IconButton>
          )}
          sx={{
            '& .MuiAvatar-root': { width: '32px', height: '32px' },
            '& .MuiAvatar-circular:hover': {
              boxShadow: shadows[5],
              '&:not(:has(button))': { boxShadow: 'none' },
            },
            zIndex: 1000,
          }}
        >
          {collaborationUsers.map(({ title, userIcon }) => (
            <Tooltip arrow title={title} key={title} placement="top">
              <Stack sx={{ height: 'fit-content' }}>{userIcon}</Stack>
            </Tooltip>
          ))}
        </AvatarGroup>
        {collaborationUsers.length <= MAX_LENGTH && (
          <Tooltip arrow title={t('tooltips.share')} placement="top">
            <IconButton color="primary" onClick={toggleModal} sx={{ ml: -1.25, zIndex: 500 }}>
              <Box component={UserPlus} sx={{ width: '18px', height: '18px' }} />
            </IconButton>
          </Tooltip>
        )}
        <ShareProjectDialog
          isOpen={isOpenShareProjectModal}
          toggleModal={toggleModal}
          project={project}
          onShare={onShare}
          onUnshare={onUnshare}
        />
      </Stack>
    </ViewHeader>
  );
};

export default Header;
