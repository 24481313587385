import { Dialog, IconButton, Stack, Typography, useTheme } from '@mui/material';
import { FC, useEffect } from 'react';
import { FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from '@tanstack/react-query';
import DeleteIcon from '@/assets/delete.svg?react';
import { ProjectFull, useGetFilesystemHook } from '@/api/generated';
import DocumentUpload from '@/views/Projects/components/ProjectFormDialog/components/DocumentUpload';
import LunchProjectButton from '@/components/SubmitButton/SubmitButton';
import ProjectForm from '@/views/Projects/components/ProjectFormDialog/components/ProjectForm';
import { STATUS } from '@/utils/enums';
import { useFileSystemTree } from '@/views/Projects/components/ProjectFormDialog/hooks/useFileSystemTree';
import { useProjectForm } from '@/views/Projects/components/ProjectFormDialog/hooks/useProjectForm';
import { updateProjectCache } from '@/utils/updateProjectCache';

interface ProjectFormDialogProps {
  isOpened: boolean;
  initialProject?: ProjectFull;
  onClose: (project?: ProjectFull) => void;
  onAfterSave: (project: ProjectFull) => void;
}

const ProjectFormDialog: FC<ProjectFormDialogProps> = ({ isOpened, initialProject, onClose, onAfterSave }) => {
  const queryClient = useQueryClient();
  const { palette } = useTheme();
  const { t } = useTranslation('projectUpdate');

  const { form, isSaving, resetFormValues, onSubmit } = useProjectForm({
    initialProject,
    onAfterSave,
  });
  const projectSlug = form.watch('slug');
  const getFileSystem = useGetFilesystemHook();

  const {
    loadFileSystem,
    fileSystemNodes,
    uploadFiles,
    onRename,
    onMove,
    onFolderCreate,
    onDelete,
    onFileOpen,
    onDocumentConvertToPage,
  } = useFileSystemTree({
    initialProject,
    getFormValues: form.getValues,
    onClose,
    onProjectCreate: createdProject => {
      form.setValue('slug', createdProject.slug);
      form.setValue('name', createdProject.name!);
    },
  });

  const isUploading = Object.entries(fileSystemNodes).some(([, node]) => node.status === STATUS.LOADING);

  useEffect(() => {
    if (!isOpened) return;
    resetFormValues(initialProject);
    loadFileSystem(initialProject);
  }, [isOpened, initialProject]);

  useEffect(() => {
    const slug = form.getValues('slug');
    if (!slug || isOpened) return;

    (async () => {
      // We update file system here to keep it in sync for documents in navigation.
      const fileSystem = await getFileSystem(slug);
      updateProjectCache({ queryClient, projectSlug: slug }, prevProject => {
        if (!prevProject) return;
        return { ...prevProject, filesystem: fileSystem };
      });
    })();
  }, [isOpened, initialProject]);

  return (
    <Dialog
      component="form"
      fullWidth
      open={isOpened}
      maxWidth="md"
      onClose={() => onClose()}
      onSubmit={onSubmit}
      PaperProps={{ sx: { backgroundColor: palette.background.default, height: '90%' } }}
    >
      <FormProvider {...form}>
        <IconButton
          color="primary"
          sx={{
            position: 'absolute',
            right: 10,
            top: 5,
            cursor: 'pointer',
            color: palette.darkPurple.light,
            display: 'block',
            '&, &:hover': { backgroundColor: 'transparent', p: 0, boxShadow: 'none' },
          }}
          onClick={() => onClose()}
        >
          <DeleteIcon />
        </IconButton>

        <Stack direction="row" sx={{ height: '100%', overflow: 'hidden' }}>
          <Stack
            flex={1}
            sx={{
              position: 'relative',
              my: 3,
              pr: 3,
              pl: 6,
              pb: 1.25,
              borderRight: `1px solid ${palette.grey[500]}`,
              overflowY: 'auto',
              scrollbarWidth: 'thin',
            }}
          >
            <Typography
              variant="h3"
              fontWeight="fontWeightMedium"
              sx={{
                color: palette.grey[800],
                textAlign: 'center',
                position: 'sticky',
                top: '0',
                pb: 5.75,
                zIndex: '100',
                width: '100%',
                background: `linear-gradient(to bottom, ${palette.background.default} 70%, transparent)`,
              }}
            >
              {projectSlug ? t('details.title.edit') : t('details.title.create')}
            </Typography>
            <ProjectForm />
          </Stack>

          <Stack flex={1} gap={3} sx={{ py: 3, pl: 3, pr: 7 }}>
            <DocumentUpload
              fileSystemNodes={fileSystemNodes}
              uploadFiles={uploadFiles}
              onRename={onRename}
              onMove={onMove}
              onFolderCreate={onFolderCreate}
              onDelete={onDelete}
              onFileOpen={onFileOpen}
              onDocumentConvertToPage={onDocumentConvertToPage}
            />
            <LunchProjectButton
              isLoading={isUploading || isSaving}
              sx={{
                alignSelf: 'center',
                minWidth: 140,
                py: 1.25,
                fontWeight: 'fontWeightMedium',
                textAlign: 'center',
                color: palette.background.default,
                backgroundColor: palette.primary.dark,
              }}
            >
              {projectSlug ? t(`button.edit`) : t(`button.create`)}
            </LunchProjectButton>
          </Stack>
        </Stack>
      </FormProvider>
    </Dialog>
  );
};

export default ProjectFormDialog;
