import orderBy from 'lodash/orderBy';
import { DocumentMetadata, FileSystem } from '@/api/generated';
import { isFolder } from '@/views/Projects/components/ProjectFormDialog/utils/isFolder';
import { STATUS } from '@/utils/enums';
import { removePdfExtension } from '@/views/Projects/components/ProjectFormDialog/utils/removePdfExtension';
import { TreeFileSystemNode } from '@/types';

export const constructFileSystemTree = (fileSystem: FileSystem, documentsById: Record<string, DocumentMetadata>) => {
  const nodesById = Object.entries(fileSystem.root!).reduce<Record<string, TreeFileSystemNode>>((acc, [id, fileOrFolder]) => {
    if (isFolder(fileOrFolder)) {
      acc[id] = {
        type: 'folder',
        id,
        name: fileOrFolder.name,
        order: fileOrFolder.order ?? 0,
        parentId: fileOrFolder.parentId && fileOrFolder.parentId !== 'root' ? fileOrFolder.parentId : undefined,
        children: acc[id] ? acc[id].children : [],
        status: STATUS.LOADED,
      };
    } else if (documentsById[id]) {
      const document = documentsById[id];
      acc[id] = {
        type: 'file',
        id,
        name: removePdfExtension(document.filename),
        order: fileOrFolder.order ?? 0,
        parentId: fileOrFolder.parentId && fileOrFolder.parentId !== 'root' ? fileOrFolder.parentId : undefined,
        document,
        status: STATUS.LOADED,
      };
    }

    if (!fileOrFolder.parentId || fileOrFolder.parentId === 'root') return acc;

    acc[fileOrFolder.parentId] = acc[fileOrFolder.parentId] ?? {
      type: 'folder',
      id,
      name: 'Temporary',
      order: 0,
      children: [],
    };

    acc[fileOrFolder.parentId].children!.push(acc[id]);

    return acc;
  }, {});

  Object.entries(nodesById).forEach(([, node]) => {
    if (!node.children) return;
    node.children = orderBy(node.children, ['type', 'order', 'desc', 'asc']);
  });

  return nodesById;
};
