import { FC, ReactNode, useState } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { AppBar, Button, Divider, IconButton, Stack, Toolbar, Tooltip, useTheme } from '@mui/material';
import Logo from '@/assets/logo.svg?react';
import NotificationsIcon from '@/assets/notifications-icon.svg?react';
import { toHome } from '@/services/linker';
import UserDropdown from '@/components/Header/UserDropdown/UserDropdown';
import Feedback from '@/views/Feedback/Feedback';
import { useOrganization } from '@/hooks/useOrganization';

export const headerId = 'header';

interface HeaderProps {
  routeHeader?: ReactNode;
}

const Header: FC<HeaderProps> = ({ routeHeader }) => {
  const { organization } = useOrganization();
  const { t } = useTranslation('common');
  const { palette, spacing } = useTheme();
  const [openFeedbackModal, setOpenFeedbackModal] = useState<boolean>(false);

  return (
    <AppBar position="sticky" sx={{ top: 0, opacity: 1, zIndex: 999, borderRadius: 0 }} id={headerId}>
      <Toolbar sx={{ display: 'flex', justifyContent: 'space-between', height: 64 }}>
        <Stack direction="row" alignItems="center" gap={3}>
          <Link to={toHome()} style={{ textDecoration: 'none', height: '39px' }} title={t('header.logo')}>
            <Logo style={{ height: '100%' }} />
          </Link>
          {routeHeader}
        </Stack>
        <Stack direction="row" alignItems="center" gap={2} height="100%">
          <Tooltip arrow title={t('tooltips.share')}>
            <Button
              variant="contained"
              color="accent"
              onClick={() => setOpenFeedbackModal(true)}
              sx={{
                px: 2,
                py: 1,
                lineHeight: 1.2,
                fontSize: 'body2.fontSize',
                borderRadius: 25,
                fontWeight: 'body2.fontWeight',
                boxShadow: 0,
                '&:hover': { boxShadow: 0 },
              }}
            >
              {t('header.feedback')}
            </Button>
          </Tooltip>
          <Divider sx={{ width: 2, height: 32, backgroundColor: palette.primary.main }} />
          <Feedback onClose={() => setOpenFeedbackModal(false)} isOpen={openFeedbackModal} />
          <IconButton
            color="primary"
            disabled
            aria-label="notification button"
            sx={{ p: 0.9, '& svg': { '& path': { fill: palette.text.primary } } }}
            title={t('header.notifications')}
          >
            <NotificationsIcon style={{ width: '20px', height: '20px' }} />
          </IconButton>
          <UserDropdown />
          <img
            src={organization.logo.url}
            width={organization.logo.width ?? 50}
            height={organization.logo.height ?? 50}
            style={{ maxWidth: 76, maxHeight: '100%', margin: spacing(0.75, 1.5) }}
            alt={t('header.systemLogo')}
          />
        </Stack>
      </Toolbar>
    </AppBar>
  );
};

export default Header;
