import { FC, useEffect, useMemo, ReactNode } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useGetProjectsIdDocumentsDocumentId } from '@/api/generated';
import { toProjectHomepage } from '@/services/linker';
import { useProject } from '@/views/Project/hooks/useProject';
import { DocumentsContext } from '@/views/Project/contexts/DocumentsContext';
import { enqueueSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';

interface DocumentsProviderProps {
  children: ReactNode;
}

const DocumentsProvider: FC<DocumentsProviderProps> = ({ children }) => {
  const { t } = useTranslation('project');
  const [searchParams, setSearchParams] = useSearchParams();
  const { projectSlug, project } = useProject();
  const { defaultDocumentId } = project;
  const navigate = useNavigate();
  const documentId = searchParams.get('documentId');
  const documents = project.documents;
  const isDocumentInProject = !!project?.documents?.find(doc => doc._id === documentId);

  const { data: document, isLoading: isLoadingDocument } = useGetProjectsIdDocumentsDocumentId(
    projectSlug,
    { document_id: documentId! },
    { query: { enabled: !!projectSlug && !!documentId && isDocumentInProject, gcTime: 0 } },
  );

  useEffect(() => {
    const nextDocumentId = defaultDocumentId || documents?.[0]?._id;
    if (documentId || !nextDocumentId) return;

    navigate(toProjectHomepage({ projectSlug, documentId: defaultDocumentId || documents?.[0]?._id }), { replace: true });
  }, [documentId, navigate, defaultDocumentId, projectSlug, documents]);

  useEffect(() => {
    if (!documentId || isDocumentInProject) return;

    setSearchParams(prevParams => {
      const nextParams = new URLSearchParams(prevParams);
      nextParams.delete('documentId');
      return nextParams;
    });
    enqueueSnackbar(t('noDocumentError'), { variant: 'error' });
  }, [documentId, isDocumentInProject]);

  const value = useMemo(
    () => ({ documentId: documentId || undefined, documents, document, isLoadingDocument }),
    [documentId, documents, document, isLoadingDocument],
  );

  return <DocumentsContext.Provider value={value}>{children}</DocumentsContext.Provider>;
};

export default DocumentsProvider;
