import { Box, CircularProgress, List, Stack, Typography, useTheme } from '@mui/material';
import { Trans, useTranslation } from 'react-i18next';
import { useDropzone } from 'react-dropzone';
import Icon from '@/components/Icon/Icon';
import { acceptedFileTypes } from '@/utils/consts';
import DropZoneArea from '@/components/DropZoneArea';
import { FC, useEffect, useState } from 'react';
import useKnowledge from '@/views/Knowledge/useKnowledge';
import { DocumentMetadata, useCheckIfAllOrgDocumentsAreVecorized, useGetDocumentFromKnowledgeBase } from '@/api/generated';
import DocumentItem from '@/views/Knowledge/components/DocumentItem';
import Dialog from '@/components/Dialog/Dialog';
import { alpha } from '@mui/system';
import { useOrganization } from '@/hooks/useOrganization';
import { FileToUpload, STATUS } from '@/views/Knowledge/types';

interface OrganizationalDocumentsProps {
  filteredFiles: string;
}

const CHECK_DOCUMENTS_REFETCH_INTERVAL = 2_000;

const OrganizationalDocuments: FC<OrganizationalDocumentsProps> = ({ filteredFiles }) => {
  const { palette } = useTheme();
  const { t } = useTranslation('common');
  const { organization } = useOrganization();
  const { uploadedFiles, deleteDocument, uploadFile } = useKnowledge();
  const [documents, setDocuments] = useState<FileToUpload[]>([]);
  const [documentId, setDocumentId] = useState('');
  const [isOpen, setIsOpen] = useState(false);

  const { data: knowledgeDocument, isLoading: knowledgeDocumentLoading } = useGetDocumentFromKnowledgeBase(
    organization.id,
    { document_id: documentId },
    {
      query: {
        enabled: isOpen,
      },
    },
  );

  const { data: checkedDocuments, refetch: refetchCheckedDocuments } = useCheckIfAllOrgDocumentsAreVecorized(organization.id, {
    query: {
      refetchInterval: query => (!query?.state.data?.isReady ? CHECK_DOCUMENTS_REFETCH_INTERVAL : false),
    },
  });

  const handleDocumentId = (id: string) => {
    setDocumentId(id);
    setIsOpen(true);
  };

  const onCloseDialog = () => setIsOpen(false);

  useEffect(() => {
    const newDocumentsList = uploadedFiles?.documents
      ?.map(document => ({
        file: document,
        status: STATUS.COMPLETED,
        id: crypto.randomUUID(),
      }))
      ?.filter(document => document.file.filename.toLowerCase().includes(filteredFiles.toLowerCase()));
    setDocuments(newDocumentsList || []);
  }, [uploadedFiles, filteredFiles]);

  const updateDocuments = (id: string, status: STATUS, updatedDocument?: DocumentMetadata) => {
    setDocuments(prevDocuments =>
      prevDocuments.map(document => (document.id !== id ? document : { status, id, file: updatedDocument || document.file })),
    );
  };

  const uploadFiles = (files: File[]) => {
    files.map(async (file: File) => {
      const id = crypto.randomUUID();
      try {
        setDocuments(prev => [...prev, { status: STATUS.LOADING, id, file: { ...file, filename: file.name } }]);
        const document = await uploadFile(file);
        updateDocuments(id, STATUS.COMPLETED, document);
        refetchCheckedDocuments();
      } catch (error) {
        updateDocuments(id, STATUS.ERROR);
      }
    });
    return;
  };

  const handleDeleteDocument = async (documentIdToDelete: string) => {
    await deleteDocument(documentIdToDelete);
    setDocuments(prevDocuments =>
      prevDocuments.filter(({ file }) => (file as DocumentMetadata | undefined)?._id !== documentIdToDelete),
    );
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: uploadFiles,
    accept: acceptedFileTypes,
  });
  const { onClick, ...rootProps } = getRootProps();

  return (
    <Stack {...rootProps} sx={{ overflow: 'hidden' }}>
      <DropZoneArea
        sx={{
          mb: 3.75,
          py: 4,
          borderRadius: 1,
          boxShadow: 0,
        }}
        onClick={onClick}
        isDragActive={isDragActive}
      >
        <input {...getInputProps()} />
        <Stack flexDirection="row" gap={1.25}>
          <Icon sx={{ color: palette.primary.dark }} name="uploadFile" fontSize="large" />
          <Typography
            sx={{
              color: palette.grey[800],
              fontWeight: 'fontWeightMedium',
            }}
          >
            <Trans
              components={{
                span: <span style={{ color: palette.primary.dark, textDecoration: 'underline' }} />,
              }}
            >
              {t('knowledge.uploadFiles.inputLabel')}
            </Trans>
          </Typography>
        </Stack>
      </DropZoneArea>
      <List
        sx={{
          p: 0,
          display: 'flex',
          flexDirection: 'column',
          gap: 0.5,
          overflowY: 'auto',
        }}
      >
        {documents.map(document => (
          <DocumentItem
            key={document.id}
            onDelete={handleDeleteDocument}
            document={document}
            handleDocumentId={handleDocumentId}
            checkedDocuments={checkedDocuments}
          />
        ))}
      </List>
      <Dialog
        width={1000}
        open={isOpen}
        onClose={onCloseDialog}
        sx={{ backgroundColor: alpha(`${palette.text.secondary}`, 0.5) }}
        PaperProps={{ sx: { '&.MuiPaper-root': { p: 0, border: 'none', boxShadow: 1 } } }}
      >
        {knowledgeDocumentLoading ? (
          <Box sx={{ height: 700, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <CircularProgress />
          </Box>
        ) : (
          <Box
            width="100%"
            height={700}
            component="iframe"
            border="0"
            src={`https://docs.google.com/viewer?url=${encodeURIComponent(knowledgeDocument?.url ?? '')}&embedded=true`}
          />
        )}
      </Dialog>
    </Stack>
  );
};

export default OrganizationalDocuments;
