import { Box, IconButton, Stack, Typography, useTheme } from '@mui/material';
import { DropEvent, FileRejection, useDropzone } from 'react-dropzone';
import { FC, useCallback, useRef, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import {
  CreateHandler,
  DeleteHandler,
  MoveHandler,
  NodeApi,
  NodeRendererProps,
  RenameHandler,
  Tree,
  TreeApi,
} from 'react-arborist';
import { useResizeObserver } from 'usehooks-ts';
import UploadIcon from '@/assets/upload-icon.svg?react';
import DropZoneArea from '@/components/DropZoneArea';
import { acceptedFileTypes } from '@/utils/consts';
import TreeDocumentNode from '@/views/Projects/components/ProjectFormDialog/components/TreeDocumentNode';
import Icon from '@/components/Icon/Icon';
import { TreeFileSystemNode } from '@/types';
import ToggledSearch from '@/components/ToggledSearch';

interface DocumentUploadProps {
  fileSystemNodes: TreeFileSystemNode[];
  uploadFiles: (files: File[], folderId?: string) => void;
  onRename: RenameHandler<TreeFileSystemNode>;
  onMove: MoveHandler<TreeFileSystemNode>;
  onFolderCreate: CreateHandler<TreeFileSystemNode>;
  onDelete: DeleteHandler<TreeFileSystemNode>;
  onFileOpen: (documentId: string) => void;
  onDocumentConvertToPage: (documentId: string) => void;
}

const DocumentUpload: FC<DocumentUploadProps> = ({
  fileSystemNodes,
  uploadFiles,
  onRename,
  onMove,
  onFolderCreate,
  onDelete,
  onFileOpen,
  onDocumentConvertToPage,
}) => {
  const { t } = useTranslation('projectUpdate');
  const { palette, spacing } = useTheme();

  const [searchTerm, setSearchTerm] = useState('');
  const treeRef = useRef<TreeApi<TreeFileSystemNode> | null>(null);
  const folderIdRef = useRef<string>();
  const treeWrapRef = useRef<HTMLDivElement | null>(null);
  const { width = 0, height = 0 } = useResizeObserver({
    ref: treeWrapRef,
    box: 'border-box',
  });

  const beforeFilesUpload = (files: File[], _: FileRejection[], event: DropEvent | null) => {
    if (!event) {
      return uploadFiles(files, folderIdRef.current);
    }

    const dropTarget = event.target as HTMLElement;
    const closestFolder = dropTarget.closest<HTMLElement>('[data-folderid]');
    const folderIdAttr = closestFolder?.dataset?.['folderid'];
    const folderId = folderIdAttr && folderIdAttr !== 'root' ? folderIdAttr : undefined;

    uploadFiles(files, folderId);
  };

  const { getRootProps, getInputProps, isDragActive, open } = useDropzone({
    onDrop: beforeFilesUpload,
    accept: acceptedFileTypes,
    onFileDialogCancel: () => (folderIdRef.current = undefined),
  });
  const { onClick, ...rootProps } = getRootProps();

  const onAddFile = useCallback((node: NodeApi<TreeFileSystemNode>) => {
    folderIdRef.current = node.id;
    open();
  }, []);

  const handleFileOpen = useCallback((node: NodeApi<TreeFileSystemNode>) => onFileOpen(node.id), []);

  const TreeDocumentNodeWrap = useCallback(
    (nodeProps: NodeRendererProps<TreeFileSystemNode>) => (
      <TreeDocumentNode
        {...nodeProps}
        onAddFile={onAddFile}
        onFileOpen={handleFileOpen}
        onDocumentConvertToPage={onDocumentConvertToPage}
      />
    ),
    [onAddFile, onFileOpen, onDocumentConvertToPage],
  );

  return (
    <Stack flex={1} {...rootProps} data-folderid="root" sx={{ minHeight: 0, height: '100%' }}>
      <Stack sx={{ minHeight: 0, pb: 5.75 }}>
        <Typography variant="h3" fontWeight="fontWeightMedium" sx={{ color: palette.grey[800], textAlign: 'center' }}>
          {t('uploadFiles.title')}
        </Typography>
      </Stack>
      <DropZoneArea sx={{ minHeight: 0, mb: 1.5, pt: 7.25, pb: 10 }} isDragActive={isDragActive} onClick={onClick}>
        <input {...getInputProps()} />
        <Stack gap={1.5} alignItems="center">
          <Box component={UploadIcon} />
          <Typography sx={{ color: palette.grey[800], fontWeight: 'fontWeightMedium' }}>
            <Trans
              components={{
                span: <span style={{ color: palette.primary.dark, textDecoration: 'underline' }} />,
              }}
            >
              {t('uploadFiles.inputLabel')}
            </Trans>
          </Typography>
        </Stack>
      </DropZoneArea>

      <Stack
        sx={{
          minHeight: 0,
          height: '100%',
          overflow: 'hidden',
          borderRadius: 4,
          boxShadow: 6,
        }}
      >
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="flex-end"
          gap={0.5}
          sx={{ width: '100%', px: 3, pt: 2, pb: 1 }}
        >
          <ToggledSearch value={searchTerm} onChange={setSearchTerm} />

          <IconButton
            size="small"
            title={t('uploadFiles.addFolder')}
            sx={{ '&:hover': { backgroundColor: palette.primary.main } }}
            onClick={() => treeRef.current?.create()}
          >
            <Icon name="newFolder" fontSize="small" htmlColor={palette.primary.dark} />
          </IconButton>
        </Stack>

        <Stack
          ref={treeWrapRef}
          sx={{
            position: 'relative',
            width: '100%',
            minHeight: 0,
            height: '100%',
            '& > div > div': { scrollbarWidth: 'thin' },
            '& [role="treeitem"]': { outline: 'none' },
          }}
        >
          {!fileSystemNodes.length && (
            <Stack
              sx={{ position: 'absolute', top: 0, right: 0, bottom: 0, left: 0, justifyContent: 'center', textAlign: 'center' }}
            >
              {t('uploadFiles.noFiles')}
            </Stack>
          )}
          <Tree
            ref={treeRef}
            width={width}
            height={height}
            openByDefault={false}
            disableMultiSelection
            paddingBottom={parseInt(spacing(2))}
            rowHeight={34}
            searchTerm={searchTerm}
            searchMatch={(node, term) => node.data.name.toLowerCase().includes(term.toLowerCase())}
            idAccessor={node => node.id}
            disableDrop={({ parentNode }) => !parentNode.isRoot && parentNode.data.type !== 'folder'}
            childrenAccessor="children"
            onMove={onMove}
            onCreate={onFolderCreate}
            onRename={onRename}
            onDelete={onDelete}
            data={fileSystemNodes}
          >
            {TreeDocumentNodeWrap}
          </Tree>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default DocumentUpload;
