import { FC, ReactNode, useCallback, useEffect } from 'react';
import { useDescope, useSession } from '@descope/react-sdk';
import { useGetUsersMe } from '@/api/generated';
import { AuthContext, AuthContextValue } from '@/contexts/AuthContext';
import { AXIOS_INSTANCE } from '@/api/customAxios';
import { AxiosError } from 'axios';
import Loader from '@/components/Loader/Loader';
import Auth from '@/containers/Auth/Auth';

interface AuthProviderProps {
  children: ReactNode;
}

const AuthProvider: FC<AuthProviderProps> = ({ children }) => {
  const descopeSdk = useDescope();
  const { isAuthenticated, isSessionLoading } = useSession();
  const { data: user, isLoading: isUserLoading } = useGetUsersMe({ query: { enabled: isAuthenticated } });

  const isLoading = isUserLoading || isSessionLoading;

  const logout = useCallback(() => descopeSdk.logout(), [descopeSdk]);

  useEffect(() => {
    AXIOS_INSTANCE.interceptors.response.use(null, function (error) {
      if (error instanceof AxiosError && error.response?.status === 401) logout();
      return Promise.reject(error);
    });
  }, [logout]);

  if (isLoading) return <Loader id="auth" />;
  if (!isAuthenticated || !user) return <Auth />;

  const value: AuthContextValue = { currentUser: user, logout };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export default AuthProvider;
