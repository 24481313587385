import { Mention } from '@/api/generated';

export const ejectDocumentMentionsFromText = (text: string) => {
  const mentions: Mention[] = [];
  const simplifiedText = text.replace(/@\[(.+?)]\((.+?)\)/g, (_, ...args) => {
    const [fileName, id] = args;
    mentions.push({ id, name: fileName, mention_type: 'document' });
    return `@${fileName}`;
  });

  return { text: simplifiedText, mentions };
};
