import { Autocomplete, InputLabel, Stack, TextField, useTheme, AutocompleteProps } from '@mui/material';
import { Control, FieldPath, FieldValues, useController } from 'react-hook-form';
import { ReactNode } from 'react';
import { getOptionValue } from '@/components/Select/getOptionValue';

export interface ListItem {
  title: string;
  value?: string;
  inputValue?: string;
}

interface SelectProps<T extends FieldValues = FieldValues, DisableClearable extends boolean | undefined = false>
  extends Omit<AutocompleteProps<ListItem | string, false, DisableClearable, true>, 'renderInput'> {
  options: (ListItem | string)[];
  label?: string;
  id: string;
  name: FieldPath<T>;
  placeholder?: string;
  control: Control<T>;
  endAdornment?: ReactNode;
}

const Select: <T extends FieldValues = FieldValues, DisableClearable extends boolean | undefined = false>(
  props: SelectProps<T, DisableClearable>,
) => ReactNode = ({ label, placeholder, options, control, name, id, endAdornment, onKeyDown, ...autocompleteProps }) => {
  const { palette } = useTheme();
  const { field, fieldState } = useController({ name, control });
  const { ref, ...fieldProps } = field;

  return (
    <Stack gap={0.6}>
      {label && (
        <InputLabel sx={{ color: palette.controls.text }} htmlFor={id}>
          {label}
        </InputLabel>
      )}

      <Autocomplete
        {...fieldProps}
        value={options.find(option => getOptionValue(option) === field.value)}
        getOptionLabel={option => (typeof option === 'string' ? option : option.title)}
        onChange={(_, option) => field.onChange(getOptionValue(option))}
        id={id}
        selectOnFocus
        handleHomeEndKeys
        openOnFocus
        options={options}
        {...autocompleteProps}
        renderInput={params => (
          <TextField
            {...params}
            onKeyDown={onKeyDown}
            inputRef={ref}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  {endAdornment}
                  {params.InputProps.endAdornment}
                </>
              ),
            }}
            error={!!fieldState.error}
            placeholder={placeholder}
          />
        )}
      />
    </Stack>
  );
};

export default Select;
