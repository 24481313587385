import { useTranslation } from 'react-i18next';
import { Button, CircularProgress, ListItem, Typography, useTheme } from '@mui/material';
import { FC } from 'react';
import { Stack } from '@mui/system';
import DocumentItemWrapper from '@/components/DocumentItemWrapper/DocumentItemWrapper';
import { isNewFile } from '@/utils/isNewFile';
import StatusDot from '@/components/StatusDot/StatusDot';
import { ReadyForQueries } from '@/api/generated';
import { FileToUpload, STATUS } from '@/views/Knowledge/types';
import Icon from '@/components/Icon/Icon';

interface DocumentItemProps {
  document: FileToUpload;
  onDelete: (documentId: string) => Promise<void>;
  handleDocumentId: (documentId: string) => void;
  checkedDocuments?: ReadyForQueries;
}

const DocumentItem: FC<DocumentItemProps> = ({ document, onDelete, handleDocumentId, checkedDocuments }) => {
  const { t } = useTranslation('common');
  const { palette, typography, spacing } = useTheme();

  const isFailed = document.status === STATUS.ERROR;
  const isLoading = document.status === STATUS.LOADING;
  const documentId = isNewFile(document.file) ? undefined : document.file._id;
  const checkedDocumentsIds = (checkedDocuments?.docs_in_progress || []).map(({ id }) => id);

  const isDocumentReady = !checkedDocumentsIds.includes(documentId ?? '');

  return (
    <ListItem
      sx={{
        p: spacing(0, 1),
        '&:hover': { backgroundColor: palette.primary.main, '& .delete-button, & .DocumentItem__preview': { opacity: 1 } },
        borderRadius: 2,
        '& .delete-button, & .DocumentItem__preview': { opacity: 0 },
      }}
    >
      <DocumentItemWrapper onDelete={() => documentId && onDelete(documentId)} sx={{ mb: 0, width: '100%' }} size="base.fontSize">
        <Button
          startIcon={
            isLoading ? (
              <CircularProgress color="inherit" size="0.75rem" />
            ) : (
              <Stack flexDirection="row" alignItems="center" gap={1}>
                <StatusDot isDocumentReady={isDocumentReady} />
                <Icon name="pdf" fontSize="small" />
              </Stack>
            )
          }
          onDoubleClick={() => documentId && handleDocumentId(documentId)}
          sx={{
            fontSize: typography.body2.fontSize,
            color: isFailed ? palette.error.main : palette.grey[700],
            '& .MuiButton-icon': { mr: 1 },
            px: 1,
            py: 0.75,
            width: '100%',
            justifyContent: 'flex-start',
          }}
        >
          <Stack sx={{ flexDirection: 'row', gap: 2 }}>
            <Typography sx={{ fontSize: 'inherit' }}>{document.file.filename}</Typography>
            {isFailed && (
              <Typography sx={{ fontSize: typography.body3.fontSize, color: document.status && palette.error.main }}>
                {t('knowledge.uploadFiles.uploadFailed')}
              </Typography>
            )}
          </Stack>
        </Button>
        <Button
          className="DocumentItem__preview"
          variant="outlined"
          onClick={() => documentId && handleDocumentId(documentId)}
          sx={{
            color: palette.primary.dark,
            fontSize: 'body3.fontSize',
            border: `1px solid ${palette.primary.dark}`,
            px: 1,
            py: 0.25,
            borderRadius: 1,
            lineHeight: 1,
            mr: 1,
            '&:hover': { border: `1px solid ${palette.primary.dark}` },
          }}
        >
          {t('knowledge.previewPdfButtonText')}
        </Button>
      </DocumentItemWrapper>
    </ListItem>
  );
};

export default DocumentItem;
